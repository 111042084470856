<template>
  <v-row>
    <v-col
      cols="12"
      class="mb-6"
      lg="4"
      md="4"
      xl="4"
    >
      <view-data
        :data="data"
        :show="dialog"
        @closeDialog="dialog=false"
      ></view-data>
    </v-col>
    <v-col
      cols="12"
    >
      <list-view @passData="passData"></list-view>
    </v-col>
  </v-row>
</template>

<script>
import { ref } from '@vue/composition-api'
import ViewData from './data.vue'
import ListView from './list.vue'

export default {
  metaInfo: {
    title: 'Payroll Change',
  },

  setup() {
    const data = ref({})
    const dialog = ref(false)

    const passData = d => {
      data.value = d
      dialog.value = true
    }

    return {
      data,
      passData,
      dialog,
    }
  },

  components: {
    ListView,
    ViewData,
  },
}
</script>
