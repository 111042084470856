<template>
  <div>
    <v-dialog
      v-model="dialog"
      persistent
      max-width="1000px"
    >
      <v-card :loading="loading">
        <v-card-text>
          <v-container v-if="dataLoaded">
            <!-- employee detail -->
            <v-row>
              <v-col
                cols="12"
                class="pb-0 mb-0"
              >
                <v-card class="d-flex align-center" elevation="0">
                  <div class="d-flex justify-space-between flex-wrap flex-md-nowrap flex-column flex-md-row">
                    <div class="mx-auto pr-1">
                      <v-img
                        width="180"
                        height="90%"
                        :src="record.employee.image !== null ? imageSource : defaultImage"
                      ></v-img>
                    </div>
                    <v-divider :vertical="$vuetify.breakpoint.mdAndUp"></v-divider>
                    <div>
                      <v-card-text class="pt-4">
                        <h3>{{ record.employee.name }}</h3>
                        <h4>{{ record.employee.employee_number }}</h4>
                      </v-card-text>
                      <v-card-text class="text--primary text-base">
                        <h6 class="pt-4">Address</h6>
                        <p>
                          {{ `${record.employee.address}, ${record.employee.residential_province.province_name}` }}
                        </p>
                      </v-card-text>
                    </div>
                  </div>
                </v-card>
              </v-col>
            </v-row>

            <v-row>
                <v-col cols="12" lg="5" md="5" offset-md="2" offset-lg="2">
                  <v-card class="align-center" elevation="0">
                    <v-card-title class="py-0">
                      <router-link
                        :to="{
                          name: 'employee-record',
                          params: {
                            data: fnx.base64_encode({
                              id: record.id,
                              employeeId: record.employee_id,
                              employeeNumber: record.employee.employee_number,
                              source: 'O'
                            })
                          }
                        }" style="text-decoration:none;">
                        <v-alert type="info">
                          Previous Status
                        </v-alert>
                      </router-link>
                    </v-card-title>
                    <v-card-text class="py-0">
                      <div>
                        <p class="text-15 mb-0 font-weight-bold">Division</p>
                        <span class="text-15">{{ record.employee_history_old.division.title }}</span>
                      </div>
                      <div class="mt-2" v-if="record.employee_history_old.department !== null">
                        <p class="text-15 mb-0 font-weight-bold">Department</p>
                        <span class="text-15">{{ record.employee_history_old.department.title }}</span>
                      </div>
                      <div class="mt-2" v-else>
                        <p class="text-15 mb-0 font-weight-bold">Outlet</p>
                        <span class="text-15">{{ record.employee_history_old.outlet.title }}</span>
                      </div>
                      <div class="mt-2">
                        <p class="text-15 mb-0 font-weight-bold">Position</p>
                        <span class="text-15">{{ record.employee_history_old.position.title }}</span>
                      </div>
                      <div class="mt-2">
                        <p class="text-15 mb-0 font-weight-bold">Pay Mode</p>
                        <span class="text-15">{{ record.employee_history_old.pay_mode }}</span>
                      </div>
                      <div class="mt-2">
                        <p class="text-15 mb-0 font-weight-bold">Working Days</p>
                        <span class="text-15">{{ record.employee_history_old.working_days }}</span>
                      </div>
                      <div class="mt-2">
                        <p class="text-15 mb-0 font-weight-bold">Basic Rate</p>
                        <span class="text-15">{{ fnx.format_number(record.employee_history_old.basic_rate, 2) }}</span>
                      </div>
                      <div class="mt-2">
                        <p class="text-15 mb-0 font-weight-bold">ECola</p>
                        <span class="text-15">{{ fnx.format_number(record.employee_history_old.ecola, 2) }}</span>
                      </div>
                      <div class="mt-2">
                        <p class="text-15 mb-0 font-weight-bold">Responsibility Allowance</p>
                        <span class="text-15">{{ fnx.format_number(record.employee_history_old.resp_allowance, 2) }}</span>
                      </div>
                      <div class="mt-2">
                        <p class="text-15 mb-0 font-weight-bold">Training Allowance</p>
                        <span class="text-15">{{ fnx.format_number(record.employee_history_old.training_allowance, 2) }}</span>
                      </div>
                    </v-card-text>
                  </v-card>
                </v-col>
                <v-col cols="12" lg="5" md="5">
                  <v-card class="align-center" elevation="0">
                    <v-card-title class="py-0">
                      <router-link :to="{
                          name: 'employee-record',
                          params: {
                            data: fnx.base64_encode({
                              id: record.id,
                              employeeId: record.employee_id,
                              employeeNumber: record.employee.employee_number,
                              source: 'O'
                            })
                          }
                        }" style="text-decoration:none;">
                        <v-alert type="success">
                          Current Status
                        </v-alert>
                      </router-link>
                    </v-card-title>
                    <v-card-text class="py-0">
                      <div>
                        <p class="text-15 mb-0 font-weight-bold">Division</p>
                        <span class="text-15">{{ record.employee_history_new.division.title }}</span>
                      </div>
                      <div class="mt-2" v-if="record.employee_history_new.department !== null">
                        <p class="text-15 mb-0 font-weight-bold">Department</p>
                        <span class="text-15">{{ record.employee_history_new.department.title }}</span>
                      </div>
                      <div class="mt-2" v-else>
                        <p class="text-15 mb-0 font-weight-bold">Outlet</p>
                        <span class="text-15">{{ record.employee_history_new.outlet.title }}</span>
                      </div>
                      <div class="mt-2">
                        <p class="text-15 mb-0 font-weight-bold">Position</p>
                        <span class="text-15">{{ record.employee_history_new.position.title }}</span>
                      </div>
                      <div class="mt-2">
                        <p class="text-15 mb-0 font-weight-bold">Pay Mode</p>
                        <span class="text-15">{{ record.employee_history_new.pay_mode }}</span>
                      </div>
                      <div class="mt-2">
                        <p class="text-15 mb-0 font-weight-bold">Working Days</p>
                        <span class="text-15">{{ record.employee_history_new.working_days }}</span>
                      </div>
                      <div class="mt-2">
                        <p class="text-15 mb-0 font-weight-bold">Basic Rate</p>
                        <span class="text-15">{{ fnx.format_number(record.employee_history_new.basic_rate, 2) }}</span>
                      </div>
                      <div class="mt-2">
                        <p class="text-15 mb-0 font-weight-bold">ECola</p>
                        <span class="text-15">{{ fnx.format_number(record.employee_history_new.ecola, 2) }}</span>
                      </div>
                      <div class="mt-2">
                        <p class="text-15 mb-0 font-weight-bold">Responsibility Allowance</p>
                        <span class="text-15">{{ fnx.format_number(record.employee_history_new.resp_allowance, 2) }}</span>
                      </div>
                      <div class="mt-2">
                        <p class="text-15 mb-0 font-weight-bold">Training Allowance</p>
                        <span class="text-15">{{ fnx.format_number(record.employee_history_new.training_allowance, 2) }}</span>
                      </div>
                    </v-card-text>
                  </v-card>
                </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="blue darken-1"
            text
            @click="closeDialog"
          >
            Close
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <snackbar
      v-if="message.length > 0"
      :show="message.length > 0"
      :text="message"
      @close="message=''"
    ></snackbar>
  </div>
</template>

<script>
import { ref, watch } from '@vue/composition-api'
import snackbar from '@/components/SnackBar.vue'
import get from '@/composables/get'
import fnx from '@/functions/fn'

export default {
  components: {
    snackbar,
  },

  props: {
    show: Boolean,
    data: Object,
  },

  setup(props, { emit }) {
    const loading = ref(false)
    const dataLoaded = ref(false)
    const dialog = ref(false)
    const record = ref(null)
    const message = ref('')
    const imageSource = ref('')
    const defaultImage = require('@/assets/images/avatars/not-available.png')

    watch(() => props.show, async val => {
      dialog.value = val
      await get(`${process.env.VUE_APP_URI}/api/payroll/payroll-change/${props.data.id}/show`, record, loading)
      imageSource.value = `${process.env.VUE_APP_URI}/image/${fnx.base64_encode(record.value.employee.image)}`
      dataLoaded.value = true
    })

    const closeDialog = () => {
      dialog.value = false
      dataLoaded.value = false
      emit('closeDialog')
    }

    return {
      fnx,
      message,
      loading,
      dialog,
      dataLoaded,
      record,
      closeDialog,
      defaultImage,
      imageSource,
    }
  },
}
</script>
